import { useTranslation } from 'react-i18next';
import { Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles } from '@material-ui/core';

import { useUser } from 'services/auth';
import { createInitials } from 'utils/components/userMenu/userMenuHelpers';

import { EmailField } from './EmailField';
import { MobileField } from './MobileField';
import { InfoRow } from '../InfoRow';

const useStyle = makeStyles(() => ({
    nameText: {
        fontWeight: 600,
    },
}));

export const ProfileInformation = () => {
    const { t } = useTranslation();
    const classes = useStyle();

    const {
        userDetails: { internalStaffNumber, gender, firstName, lastName, weeklyLimits },
    } = useUser();
    const { maxHoursPreference, maxDaysPreference } = weeklyLimits;

    return (
        <List>
            <ListItem divider>
                <ListItemText className={classes.nameText}>
                    <span className={classes.nameText}>{`${firstName} ${lastName}`}</span>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Avatar>{createInitials(firstName, lastName)}</Avatar>
                </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider>
                <EmailField />
            </ListItem>

            <ListItem divider>
                <MobileField />
            </ListItem>

            <InfoRow title={t('portal:staff_no')} info={internalStaffNumber} />

            <InfoRow title={t('common:gender')} info={gender} />

            {maxHoursPreference && (
                <InfoRow title={t('portal:max_preferred_hours_a_week')} info={weeklyLimits.maxHoursPreference} />
            )}

            {maxDaysPreference && (
                <InfoRow title={t('portal:max_preferred_days_a_week')} info={weeklyLimits.maxDaysPreference} />
            )}
        </List>
    );
};
