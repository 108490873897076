import PropTypes from 'prop-types';
import { makeStyles, Tabs, Tab, Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        background: theme.palette.common.white,
        width: '100%',
    },
    sticky: {
        top: 0,
        position: 'sticky',
        zIndex: 1,
        background: theme.palette.common.white,
    },
    tabButton: {
        padding: theme.spacing(1),
        color: theme.palette.grey[600],
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.5vw',
            padding: '0px',
        },
    },
    activeTab: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: '3vw',
        },
    },
    hiddenTab: {
        display: 'none',
    },
    tabSize: {
        maxWidth: '25%',
    },
}));

export const TabbedWrapper = ({ tabs, tabValue, handleTabChange, color, disableRipple, classesOverrides }) => {
    const classes = useStyles();
    const createLabel = (name, index) => (
        <Typography
            className={classNames({
                [classes.tabButton]: true,
                [classes.activeTab]: tabValue === index,
            })}
        >
            {name}
        </Typography>
    );

    return (
        <Tabs
            value={tabValue}
            indicatorColor={color}
            textColor={color}
            onChange={handleTabChange}
            classes={{
                root: classNames({
                    [classes.tabRoot]: true,
                    [classes.sticky]: true,
                }),
                ...classesOverrides,
            }}
        >
            {tabs.map(({ name, isDisplayed, value }, index) => {
                if (!isDisplayed) {
                    return null;
                }

                return (
                    <Tab
                        classes={{ root: classes.tabSize }}
                        key={name}
                        disableRipple={disableRipple}
                        label={createLabel(name, index)}
                        value={value}
                    />
                );
            })}
        </Tabs>
    );
};

TabbedWrapper.propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    tabValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.any,
            component: PropTypes.node,
        }),
    ),
    disableRipple: PropTypes.bool,
    classesOverrides: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary']),
};
