import { Grid, makeStyles, styled, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IPasswordErrorAlert {
    errors: string[] | string[][];
}

const Alert = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
    '& > ul': {
        listStyleType: 'none',
        padding: theme.spacing(1),
        margin: 0,
        '& > li': {
            '& > ul': {
                listStyle: 'outside',
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(4),
            },
        },
    },
}));

const uppercaseFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const PasswordErrorAlert = ({ errors }: IPasswordErrorAlert) => {
    const { t } = useTranslation();
    return (
        <Alert xs={12}>
            <ul>
                {errors.map((error) => (
                    <li key={typeof error === 'object' ? error[0] : error}>
                        <Typography variant="body1">
                            {t(
                                `user:internal_errors.${
                                    typeof error === 'string' ? error.toLowerCase() : 'illegal_password'
                                }`,
                            )}
                        </Typography>
                        {typeof error === 'object' && (
                            <ul>
                                {error.map((rule) => (
                                    <li key={rule}>
                                        <Typography variant="body2">{uppercaseFirstLetter(rule)}</Typography>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </Alert>
    );
};
