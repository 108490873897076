import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Switch, FormControlLabel, Collapse, Divider } from '@material-ui/core';

import CheckBox from 'components/checkboxes/Checkbox';

import { useUser } from 'services/auth';
import { useUpdatePreferences } from 'services/editSelf';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    divider: {
        width: '100%',
        margin: theme.spacing(2, 0),
    },
    label: {
        width: '100%',
        margin: 0,
        justifyContent: 'space-between',
    },
    checkboxContainer: {
        width: '100%',
        textAlign: 'right',
        marginRight: '14px',
    },
}));

export const ShiftOfferPreferences = ({ onDoNotNotify }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { updatePreferencesMutation } = useUpdatePreferences();
    const {
        userDetails: {
            preferences: { shiftOffer },
        },
    } = useUser();

    const [shouldNotify, setShouldNotify] = useState(shiftOffer.email || shiftOffer.sms);
    const [notifyByEmail, setNotifyByEmail] = useState(shiftOffer.email);
    const [notifyByText, setNotifyByText] = useState(shiftOffer.sms);

    const handleShiftOfferChange = (event) => {
        const key = event.target.name;
        const value = !shiftOffer[key];

        updatePreferencesMutation.mutate({ type: 'shiftOffer', key, value });
    };

    if (updatePreferencesMutation.isError) {
        dispatch(setSnackBarError({ message: 'user:error_saving_preferences' }));
        dispatch(setShowSnackbar({ open: true }));

        updatePreferencesMutation.reset();
    }

    return (
        <>
            <Divider className={classes.divider} />

            <FormControlLabel
                classes={{ root: classes.label }}
                control={
                    <Switch
                        checked={shouldNotify}
                        color="primary"
                        onChange={(event) => {
                            setShouldNotify(!shouldNotify);

                            if (!event.currentTarget.checked) {
                                onDoNotNotify();
                            }
                        }}
                    />
                }
                label={t('user:notify_me_when_shift_offered')}
                labelPlacement="start"
                data-testid="Preferences-ShiftOfferPreferences"
            />
            <div className={classes.checkboxContainer}>
                <Collapse in={shouldNotify}>
                    <>
                        <CheckBox
                            name="email"
                            color="primary"
                            label={t('user:by_email')}
                            onClick={(e) => {
                                setNotifyByEmail(e.target.checked);
                                handleShiftOfferChange(e);
                            }}
                            checked={notifyByEmail}
                        />
                        <CheckBox
                            name="sms"
                            color="primary"
                            label={t('user:by_sms')}
                            onClick={(e) => {
                                setNotifyByText(e.target.checked);
                                handleShiftOfferChange(e);
                            }}
                            checked={notifyByText}
                        />
                    </>
                </Collapse>
            </div>
        </>
    );
};

ShiftOfferPreferences.propTypes = {
    onDoNotNotify: PropTypes.func,
    onChange: PropTypes.func,
};
