import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, makeStyles, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    baseChipStyle: {
        width: 20,
        boxShadow: 'none',
        borderRadius: 4,
        cursor: 'default',
        background: 'none',
    },
    bigChipStyle: {
        height: 40,
    },
    smallChipStyle: {
        height: 20,
        '&:first-child': {
            marginBottom: 1,
        },
    },
    pastChipStyle: {
        background: theme.palette.grey.lightest,
    },
    pendingTimeOffChipStyle: {
        background: theme.palette.warning.dark,
    },
    approvedTimeOffChipStyle: {
        background: theme.palette.primary.main,
    },
    sickDayChipStyle: {
        background: theme.palette.danger.main,
    },
    chipTextColor: {
        color: 'white',
    },
    baseTextColor: {
        color: theme.palette.grey.lightest,
    },
    emptyTextColor: {
        fontSize: '36px',
    },
}));

export const RotaChip = ({ chipSize, chipColor, isPast, chipType, status }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const matchText = () => {
        if (chipType === 'empty') {
            return '-';
        }
        if (chipType.includes('dayOff')) {
            return t('schedule:abbreviations.day_off');
        }
        if (chipType.includes('timeOff')) {
            return t('schedule:abbreviations.holiday');
        }
        if (chipType.includes('sickness')) {
            return t('schedule:abbreviations.sickness');
        }
    };

    return (
        <Card
            className={classNames({
                [classes.baseChipStyle]: true,
                [classes.bigChipStyle]: chipSize === 'big',
                [classes.smallChipStyle]: chipSize === 'small',
                [classes.pastChipStyle]: isPast && chipType !== 'dayOff' && chipType !== 'empty',
                [classes.pendingTimeOffChipStyle]: !isPast && chipType === 'timeOff' && status === 'pending',
                [classes.approvedTimeOffChipStyle]: !isPast && chipType === 'timeOff' && status === 'approved',
                [classes.sickDayChipStyle]: !isPast && chipType === 'sickness',
            })}
            style={{ background: `#${chipColor}` }}
        >
            <Grid container alignItems="center" justifyContent="center" direction="column" style={{ height: '100%' }}>
                <Grid item>
                    <Typography
                        align="center"
                        variant="caption"
                        className={classNames({
                            [classes.emptyTextColor]: chipType === 'empty',
                            [classes.baseTextColor]: chipType === 'empty' || chipType.includes('dayOff'),
                            [classes.chipTextColor]: !(chipType === 'empty' || chipType.includes('dayOff')),
                        })}
                    >
                        {matchText()}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

RotaChip.propTypes = {
    chipSize: PropTypes.string.isRequired,
    isPast: PropTypes.bool.isRequired,
    chipType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    chipColor: PropTypes.string,
};
