import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { allEventsNotifications, userEventsNotifications } from './helpers';

import { useUser } from 'services/auth';
import { useUpdatePreferences } from 'services/editSelf';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        marginTop: theme.spacing(1),
    },
}));

export const RotaNotificationTypePreference = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { updatePreferencesMutation } = useUpdatePreferences();
    const {
        userDetails: {
            preferences: { rota },
        },
    } = useUser();

    const [notificationType, setNotificationType] = useState(rota.notificationType);

    const handleChange = (event) => {
        updatePreferencesMutation.mutate({ type: 'rota', key: 'notificationType', value: event.target.value });
    };

    if (updatePreferencesMutation.isError) {
        dispatch(setSnackBarError({ message: 'user:error_saving_preferences' }));
        dispatch(setShowSnackbar({ open: true }));

        updatePreferencesMutation.reset();
    }

    return (
        <RadioGroup
            aria-label={t('user:rota_notification_type')}
            name="rota-notification-type"
            value={notificationType}
            onChange={(e) => {
                setNotificationType(e.target.value);
                handleChange(e);
            }}
            className={classes.radioGroup}
        >
            <FormControlLabel
                value={allEventsNotifications}
                control={<Radio color="primary" />}
                label="Always send me rota notifications"
            />
            <FormControlLabel
                value={userEventsNotifications}
                control={<Radio color="primary" />}
                label="Only notify if the rota has shifts or time offs for me"
            />
        </RadioGroup>
    );
};
