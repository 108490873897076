export const hydrateTimeOffWithHexColour = (allTimeOff, allTimeOffTypes) =>
    allTimeOff.map((timeOff) => {
        if (timeOff.typeId) {
            const timeOffType = allTimeOffTypes.find((type) => timeOff.typeId === type.id);

            if (timeOffType) {
                return {
                    ...timeOff,
                    name: timeOff.type,
                    type: timeOffType.type,
                    hexColor: timeOffType.backgroundColor,
                };
            }

            return {
                ...timeOff,
                name: timeOff.type,
                type: '',
                hexColor: '',
            };
        }

        return {
            ...timeOff,
            name: timeOff.type,
            type: timeOff.type,
            hexColor: '',
        };
    });
